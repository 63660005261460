import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #111;
    --white: #fff;
    --fontBlack: hsla(0, 0%, 0%, 0.87);
    --purple: hsl(270, 90%, 50%);
    --purpleDark: hsl(270, 90%, 40%);
    --purpleHighlight: hsla(270, 90%, 70%, 0.3);
    --grey1: #e3e3e3;
    --lightGrey: #f2f2f2;
    --lighterGrey: #f5f5f5;
    --lightestGrey: #fafafa;
    --green: hsl(162, 100%, 50%);
    --greenDark: hsl(162, 100%, 40%);
    --greenHighlight: hsla(162, 100%, 50%, 0.3);
    --grey2: #434343;
    --darkGrey: #3e3e3e;
    --darkerGrey: #2f2f2f;
    --darkestGrey: #212121;

    /* Default theme colours */
    --primaryColour: var(--purple);
    --highlightColour: var(--purpleHighlight);
    --accentColour: var(--purpleDark);
    --fontColour: var(--fontBlack);
    --invertFontColour: var(--white);
    --linkColour: var(--primaryColour);
    --linkHighlight: var(--highlightColor);
    --navLinkColour: var(--black);

    /* Default theme backgrounds */
    --level1: var(--lightestGrey);
    --level2: var(--lighterGrey);
    --level3: var(--lightGrey);
    --level4: var(--grey1);

    /* Box shadows */
    --shadow1: 0 0.9px 1.4px rgba(0, 0, 0, 0.07),
               0 2.3px 3.6px rgba(0, 0, 0, 0.048),
               0 4.6px 7.4px rgba(0, 0, 0, 0.039),
               0 9.5px 15.3px rgba(0, 0, 0, 0.031),
               0 26px 42px rgba(0, 0, 0, 0.022);
    --shadow2: 0 1.2px 1.9px rgba(0, 0, 0, 0.07),
               0 3.3px 5.3px rgba(0, 0, 0, 0.046),
               0 7.8px 12.7px rgba(0, 0, 0, 0.035),
               0 26px 42px rgba(0, 0, 0, 0.024);
    --shadow3: 0 1.7px 2.8px rgba(0, 0, 0, 0.07),
               0 5.8px 9.4px rgba(0, 0, 0, 0.042),
               0 26px 42px rgba(0, 0, 0, 0.028);
    --shadow4: 0 3.3px 5.3px rgba(0, 0, 0, 0.07),
               0 26px 42px rgba(0, 0, 0, 0.035);
  }

  .dark {
    /* Dark theme colours */
    --primaryColour: var(--green);
    --highlightColour: var(--greenHighlight);
    --accentColour: var(--greenDark);
    --fontColour: var(--white);
    --invertFontColour: var(--black);
    --linkColour: var(--primaryColour);
    --linkHighlight: var(--highlightColor);
    --navLinkColour: var(--white);

    /* Dark theme backgrounds */
    --level1: var(--darkGrey);
    --level2: var(--darkerGrey);
    --level3: var(--darkestGrey);
    --level4: var(--black);
  }

  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    background-color: var(--level3);
    color: var(--fontColour);
    transition: background-color 0.2s ease-in-out, color 0.25s ease-out;
  }

  .page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;

export default GlobalStyles;
