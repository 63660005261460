import { createGlobalStyle } from 'styled-components';
import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/800.css';

const Typography = createGlobalStyle`
  :root {
    --bodyWt: 400;
    --subheadingWt: 600;
    --headingWt: 800;
  }

  html {
    font-family: 'Inter', --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--fontColour);
  }

  body {
    line-height: 1.5rem;
    font-weight: var(--bodyWt);
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: var(--headingWt);
  }

  h1, h2, h3 {
    line-height: 1.1;
  }

  h4, h5 {
    line-height: 1.2;
  }

  h6, .subheading {
    line-height: 1.5
  }

  h1 {
    font-size: clamp(2.25rem, 2.7vw + 1rem, 3rem);
    min-height: 0vh;
  }

  h2 {
    font-size: clamp(2rem, 1.7vw + 1rem, 2.25rem);
    min-height: 0vh;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1.125rem
  }

  .subheading {
    font-size: 1rem;
    font-weight: var(--subheadingWt);
  }

  .primary {
    color: var(--primaryColour);
  }

`;

export default Typography;
