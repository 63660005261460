import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  position: sticky;
  bottom: 0;
  background: var(--level4);
  padding: 1rem 2rem;
`;

export default function Footer() {
  return (
    <FooterStyles>
      <small>&copy; {new Date().getFullYear()} Renee Quinn</small>
    </FooterStyles>
  );
}
