import { createGlobalStyle } from 'styled-components';

const LinkStyles = createGlobalStyle`
  a {
    color: inherit;
    text-decoration: none;
  }

  .inline-link {
    box-shadow: inset 0px 0px 0px transparent, 0px 2px 0px var(--linkColour);
    transition: box-shadow 0.3s ease-out;


    &:hover, &:focus {
      color: inherit;
      box-shadow: inset 0px -6px 0px var(--linkHighlight), 0px 2px 0px var(--linkColour);
    }
  }

  .nav-link {
    position: relative;
    padding: 0.25rem 0;
    display: inline-block;

    &:after {
      display: block;
      content: '';
      position: absolute;
      background-color: var(--linkColour);
      width: 0;
      bottom: -2px;
      height: 3px;
      left: 50%;
      transition: width 0.25s ease-out, left 0.25s ease-out;
    }

    &:hover,
    &:focus {
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }

  .icon-link {
    svg {
      circle {
        transition: fill 0.25s ease-out;
        fill: var(--primaryColour);
      }
      path {
        fill: var(--invertFontColour);
      }
    }

    &:hover,
    &:focus {
      svg circle {
        fill: var(--accentColour);
      }
    }
  }
`;

export default LinkStyles;
