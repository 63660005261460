import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ThemeToggle from './ThemeToggle';

const HeaderStyles = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  flex-wrap: wrap;
  padding: calc(1.5rem + 6px) 2rem 1.5rem;
  background: var(--level1);
  box-shadow: var(--shadow1);

  &:before {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 6px;
    width: 100%;
    background: linear-gradient(to right, var(--purple), var(--green));
  }

  .logo {
    font-size: 2rem;
    line-height: 1.1;
    font-weight: var(--headingWt);
    display: inline-block;
  }

  nav {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
  }
`;

export default function Header() {
  return (
    <HeaderStyles>
      <nav>
        <Link to='/' className='logo nav-link'>
          Renee Quinn
        </Link>
        <ThemeToggle />
      </nav>
    </HeaderStyles>
  );
}
