import React from 'react';
import styled from 'styled-components';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import LinkStyles from '../styles/LinkStyles';
import Header from './Header';
import Footer from './Footer';

const MainContainer = styled.main`
  max-width: 1200px;
  padding: 0 2rem;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default function Layout({ children }) {
  console.log(
    '%c🤠 Are you just gonna peek into the console without saying howdy?',
    'font-size: 20px;'
  );
  console.log('%cMade with ❤️ and Gatsby by Renee Quinn', 'color: deeppink;');
  return (
    <div className='page-wrapper'>
      <GlobalStyles />
      <Typography />
      <LinkStyles />
      <Header />
      <MainContainer>{children}</MainContainer>
      <Footer />
    </div>
  );
}
