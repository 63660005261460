import React from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import styled from 'styled-components';
import Sun from '../assets/icons/sun.svg';
import Moon from '../assets/icons/moon.svg';

const ToggleStyles = styled.div`
  display: flex;
  align-items: center;

  .toggle {
    position: relative;
    cursor: pointer;
    display: inline-block;
  }

  .toggle-track {
    background: var(--primaryColour);
    color: var(--invertFontColour);
    width: 60px;
    height: 34px;
    padding: 0;
    border-radius: 30px;
    transition: all 0.25s ease-out;
  }

  .toggle-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    transition: opacity 0.25s ease-out;

    &.dark {
      left: 5px;
      opacity: 0;
    }

    &.light {
      right: 5px;
    }
  }

  .toggle-control {
    position: absolute;
    top: 2px;
    left: 3px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--invertFontColour);
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transform: translateX(0);
  }

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    border: 0;
  }

  input:checked + .toggle-track {
    .toggle-icon.dark {
      opacity: 1;
    }

    .toggle-icon.light {
      opacity: 0;
    }

    .toggle-control {
      transform: translateX(25px);
    }
  }
`;

export default function ThemeToggle() {
  const toggler = (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <label
          htmlFor='theme-toggle'
          className='toggle'
          title='Switch between dark and light mode'
        >
          <input
            type='checkbox'
            id='theme-toggle'
            onChange={(e) => toggleTheme(e.target.checked ? 'dark' : 'light')}
            checked={theme === 'dark'}
            aria-label='Switch between dark and light mode'
          />
          <div className='toggle-track'>
            <Moon className='toggle-icon dark' />
            <Sun className='toggle-icon light' />
            <div className='toggle-control'></div>
          </div>
        </label>
      )}
    </ThemeToggler>
  );
  return <ToggleStyles>{toggler}</ToggleStyles>;
}
